import { $fetch, $post, $delete } from '@/services';

export function fetch(data) {
  return $fetch(`/member/customerVerifyFlow/search`, data);
}

export function load(id) {
  return $fetch(`/member/customerVerifyFlow/${id}`);
}

export function post(data) {
  return $post(`/member/customerVerifyFlow`, data);
}

export function approvalAndProcess(data) {
  return $post(`/member/customerVerifyFlow/approvalAndProcess`, data);
}

export function remove(id) {
  return $delete(`/member/customerVerifyFlow/${id}`);
}
