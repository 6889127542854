<template>
  <NModal v-bind="attrs" class="n-modal-customer-verify-flow">
    <!-- 客户信息 -->
    <IdentificationDescriptions :identification="identification" />
    <!-- 备注 -->
    <div class="h-[34px] text-base font-bold text-gray-600 opacity-90 mt-10"><comment-outlined /> {{ $tl('comment') }}</div>
    <NForm v-bind="formAttrs">
      <NTextarea v-model:value="model.comment" label="" name="comment" :auto-size="commentAutoSize" span2 />
    </NForm>
    <template #footer>
      <NButton @click="destroy()">{{ $tl('close') }}</NButton>
      <NButton :disabled="btnDisabled" class="n-bg-error" @click="onProcess(REJECT)">
        <template #icon><MinusCircleOutlined /></template> {{ $tl('reject') }}
      </NButton>
      <NButton :disabled="btnDisabled" class="n-bg-success" @click="onProcess(APPROVED)">
        <template #icon><CheckCircleOutlined /></template> {{ $tl('approve') }}
      </NButton>
    </template>
  </NModal>
</template>

<script setup>
import { isEmpty } from 'lodash-es';
import { MAX_LENGTH } from '@admin/constant';
import { computed, ref, onMounted, reactive } from 'vue';
import { successMessage, warningMessage } from '@/utils/message';
import { load, approvalAndProcess } from '@admin/api/member/customer-verify-flow';
import { useBootstrap, useInjectModalData, useLanguage, useLayout, useValidate } from '@admin/hooks';

const props = defineProps({ id: Number });

const { destroy, onReload } = useInjectModalData();
const { tl } = useLanguage();
const { collapsed } = useLayout();
const { ENUM } = useBootstrap();

const { REJECT, APPROVED } = ENUM.value.ApprovalStatus;

const identification = ref({});
const verifyFlow = ref({});

const formRef = ref();
const model = reactive({ id: null, approvalStatus: null, comment: null });
const btnDisabled = ref(false);
const commentAutoSize = { minRows: 2, maxRows: 5 };

const validation = computed(() => ({
  id: { required: true },
  approvalStatus: { required: true },
  comment: { required: model.approvalStatus === REJECT, maxLength: MAX_LENGTH.COMMENT },
}));

const { rules, clearValidate, validate } = useValidate({ formRef, validation });

const attrs = computed(() => ({
  width: '100%',
  wrapClassName: 'full-modal',
  customTitle: tl('identityVerify'),
  class: collapsed.value ? 'collapsed' : '',
}));

const formAttrs = computed(() => ({
  ref: formRef,
  model,
  rules: rules.value,
  class: '!p-0 !m-0',
}));

onMounted(() => {
  return onLoad();
});

const onLoad = () => {
  return load(props.id).then(r => {
    identification.value = r?.customerIdentificationPo;
    verifyFlow.value = r?.customerVerifyFlowPo;
    model.id = verifyFlow.value.id;
  });
};

const onProcess = async approvalStatus => {
  model.approvalStatus = approvalStatus;
  btnDisabled.value = true;
  await clearValidate();
  let isValid = false;
  try {
    await validate();
    isValid = true;
  } catch (e) {
    isValid = isEmpty(e.errorFields);
  } finally {
    btnDisabled.value = false;
  }
  if (!isValid) return;
  await approvalAndProcess(model)
    .then(() => {
      destroy();
      approvalStatus === APPROVED ? successMessage('approved', 'identity') : warningMessage('rejected', 'identity');
      return onReload();
    })
    .finally(() => (btnDisabled.value = false));
};
</script>
